import { LoadingScreen } from '@/components/LoadingScreen';
import { AgencyContext } from '@/utils/context/Agency.context';
import { AgencySelectModalContext } from '@/utils/context/AgencySelectModal.context';
import { UserContext } from '@/utils/context/User.context';
import { firestore } from '@/utils/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Agency } from '../../../types/global';

export default function AgencyProvider({ loadingScreen, children }: PropsWithChildren & { loadingScreen?: React.ReactElement }) {
    const router = useRouter()
    const user = useContext(UserContext)
    const agencySelectModalController = useContext(AgencySelectModalContext)
    const [agency, setAgency] = useState<Agency | undefined>()

    useEffect(() => {
    }, [agency])

    const fetchAgency = (unsub: any) => {
        if (!user) {
            return router.push("/auth/login")
        }
        if (!user.agencies?.length) {
            router.push("/auth/signup/create") as any
            return
        }

        let aid = window.localStorage.getItem("agency")
        if (!aid) {
            if (user.agencies.length === 1) {
                aid = user.agencies[0].id
                window.localStorage.setItem("agency", user.agencies[0].id)
            } else {
                agencySelectModalController?.query(() => fetchAgency(unsub))
                return
            }
        }

        unsub = onSnapshot(doc(firestore, "agencies", aid), (snap) => {
            console.log("AGENCY CHANGED")
            const agencyData = snap.data() as Agency
            setAgency({
                ...agencyData,
                id: aid as string,
                update: (newAgency: Partial<Agency>) => {
                    setAgency((prev) => ({ ...prev, ...newAgency }) as Agency & { id: string })
                }
            })
        }, (err) => {
            window.localStorage.removeItem("agency")
            agencySelectModalController?.query(() => fetchAgency(unsub))
        })
    }


    useEffect(() => {
        // @ts-ignore
        let unsub;
        fetchAgency(unsub)
        return () => {
            // @ts-ignore
            if (unsub) unsub();
        }
    }, [user])

    useEffect(() => {
        if (!agency || !user) return;
        // @ts-ignore
        if (!window?.Tawk_API) return;
        try {
            console.log("Setting tawk attributes")
            // @ts-ignore
            window?.Tawk_API?.setAttributes({
                // @ts-ignore
                "user-id": auth.currentUser.uid,
                "user-name": user.name,
                "email": user.email,
                "agency-id": agency.id,
                "agency-name": agency.name,
                "agency-plan": agency.plan,
                "agency-stripe-id": agency.stripeID
                // @ts-ignore
            }, (err) => console.log(err));
        } catch (err) {
            console.error("Failed to set Tawk attributes")
        }
    }, [agency])

    if (!agency) {
        return loadingScreen ? <>{loadingScreen}</> : <LoadingScreen />
    }

    return (
        <AgencyContext.Provider value={agency}>{children}</AgencyContext.Provider>
    )
}

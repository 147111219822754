import { LoadingScreen } from '@/components/LoadingScreen'
import { UserContext } from '@/utils/context/User.context'
import { auth, firestore } from '@/utils/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore'
import { useRouter } from 'next/navigation'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { User } from '../../../types/global'

export default function UserProvider({ loadingScreen, children }: PropsWithChildren & { loadingScreen?: React.ReactElement }) {
    const router = useRouter()
    const [user, setUser] = useState<User | undefined>()

    useEffect(() => {
        let userDocListenerUnsub: Unsubscribe | undefined;
        const unsub = onAuthStateChanged(auth, (usr) => {
            console.log("Auth state has changed. Setting up user listener")
            if (usr) {
                userDocListenerUnsub = onSnapshot(doc(firestore, "users", usr.uid), (snap) => {
                    const userData = snap.data() as User

                    setUser({
                        ...userData, update: (newUser: User) => {
                            setUser((prev) => ({ ...prev, ...newUser }))
                        }
                    } as User)
                })

            } else {
                router.push("/auth/login")
            }
        })
        return () => {
            unsub()
            if (!userDocListenerUnsub) return;
            userDocListenerUnsub()
        }
    }, [])

    if (!user) {
        return loadingScreen ? <>{loadingScreen}</> : <LoadingScreen />
    }

    return (
        <UserContext.Provider value={user}>{children}</UserContext.Provider>
    )
}

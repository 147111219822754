/*
    PLATFORM WIDE GUIDES
    Platform wide guides are custom components that replace the default grant workflow. They get access to the invite and update the grant status on their own.
*/

import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AccountTypes, AnyService, BaseServiceConfig, Invite, RequestedServices, ServiceConfig } from '../../../../types/global'
import { GrantsStatus } from './GrantsContainer'
import { ActionIcon, Box, Button, Divider, Image, Loader, MultiSelect, Paper, Text, alpha, useMantineTheme } from '@mantine/core'
import MetaPartnerManager from '../oauth/MetaPartnerManager'
import { circleNumberIcons } from '@/static/numberIcons'
import Link from 'next/link'
import { ArrowLeft, ArrowRight, Badge, CircleCheck, ExternalLink, IdBadge, InfoCircle, Settings } from 'tabler-icons-react'
import CopyBox from '../CopyBox'
import api from '@/utils/api'
import { roleValueToLabel } from '@/static/roles'
import { PlatformsContext } from '@/utils/context/Platforms.context'
import { GrantedChip, Selector } from './ServiceConfigSelector'
import { useElementSize } from '@mantine/hooks'
import { InstructionalImageRenderer } from './ManualInstructions'
import { useManualGrantStatus } from './ManualAccessGrantsListener'
import { InviteContext } from '@/utils/context/Invite.context'
import { useGrantRequest } from '@/utils/helpers/useGrantRequest'
import { ManualGrantsContext } from '@/utils/context/ManualGrants.context'
import Error, { ErrorList } from '../Error'
import { useGrantStatus } from '@/hooks/useGrantStatus'
import { ThemeContext } from '@/utils/context/Theme.context'
import { markServiceGranted } from '@/utils/helpers/markAsGranted'
import { InlinePageLink } from '../InlineLink'

type PlatformWideGuideProps = {
    invite: Invite,
}

const pollingURIs = {
    "Meta Ads": "/manual/status/auto/meta/ads",
    "Instagram": "/manual/stats/auto/meta/instagram",
    "Facebook Pages": "/",
} as Record<AnyService, string>

const ServiceGrantStatus = ({
    platform,
    serviceConfig,
    requestedServices,
    accounts,
    service
}: { platform: AccountTypes, serviceConfig: BaseServiceConfig, service: AnyService, requestedServices: RequestedServices, accounts: Record<AnyService, Array<{ account_id: string, id: string, page?: string }>> }) => {
    const platforms = useContext(PlatformsContext)
    const invite = useContext(InviteContext)
    const theme = useMantineTheme()

    const manualGrantResources = useMemo(() => {
        console.log("reevaluating resources")
        const resources = serviceConfig[service].map((r) => {
            if (service === "Instagram") {
                return {
                    instagramAccountID: r,
                    pageID: accounts?.Instagram?.find(a => a.id === r)?.page as string
                }
            };
            return r
        })
        return resources
    }, [serviceConfig])

    const { grantStatus, isGranted } = useManualGrantStatus({
        accountType: "Meta",
        inviteID: invite.id,
        resources: manualGrantResources,
        service,
        readOnlyGranted: false
    })

    useEffect(() => {
        if (!isGranted) return;
        // markServiceGranted(invite.id, platform, service)
    }, [isGranted])

    return (<Box>
        <Box className='flex aic' style={{ gap: 10 }}>
            <Box bg={"#fff"} className='flex aic jcc' style={{ borderRadius: 100, width: 25, height: 25, overflow: "hidden" }}>
                <Image width={18} height={18} alt={`${service} logo`} style={{ borderRadius: 0, objectFit: "contain", backgroundColor: "#fff", padding: 0 }} src={`/images/logos/${service?.toLowerCase().replaceAll(" ", "_")}.png`} />
            </Box>
            <div>
                <Text fw={400} fz={"sm"}>{service}</Text>
                {/* <Text  fz={"xs"} c="dimmed">{roleValueToLabel(service, requestedServices[service as AnyService].accessLevel, platforms)}</Text> */}
            </div>
        </Box>
        <Box ml={20}>
            {serviceConfig[service].map((a, i) => {
                const isGranted = grantStatus[a]
                return (
                    <Box key={i} className='flex aic jcsb'>
                        <Box className='flex' style={{ gap: 10 }}>
                            <Box style={{ borderLeft: "2px solid #ccc", borderBottomLeftRadius: 5, borderBottom: "2px solid #ccc", width: 15, height: 20 }}></Box>
                            <Box mt={7}>
                                <Text fz="sm">{accounts[service as AnyService].find((acc) => acc.id === a)?.account_id}</Text>
                                <Box className='flex aic' style={{ gap: 4 }}>
                                    <IdBadge size={15} color='var(--mantine-color-dimmed)' />
                                    <Text fz="xs" fw={600} c="dimmed">{roleValueToLabel(service, requestedServices[service as AnyService].accessLevel, platforms)}</Text>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='flex aic' py={3} px={10} style={{ borderRadius: 10, gap: 6 }} bg={isGranted ? alpha(theme.colors["green"][6], .1) : alpha(theme.colors["orange"][6], .1)}>
                            <Box bg={isGranted ? "green" : "orange"} style={{ width: 6, height: 6, borderRadius: 20 }} />
                            <Text fz="xs" c={isGranted ? "green" : "orange"} fw={600}>{isGranted ? "Granted" : "Waiting"}</Text>
                        </Box>
                    </Box>
                )
            })}
        </Box>
    </Box>
    )
}

const GrantStatuses = ({ serviceConfig, requestedServices, accounts }: {
    serviceConfig: BaseServiceConfig,
    requestedServices: RequestedServices,
    accounts: Record<AnyService, Array<{ account_id: string, id: string }>>
}) => {
    const manualGrants = useContext(ManualGrantsContext)
    const theme = useContext(ThemeContext)
    const invite = useContext(InviteContext)
    const [loading, setLoading] = useState(false)
    return (
        <>
            <Box className='flex fdc' bg="gray.0" style={{ borderRadius: 10, gap: 10 }} p="xs" mt={10}>
                {Object.keys(serviceConfig).map((s) => {
                    if (!manualGrants.includes(s as AnyService)) return null;
                    return <ServiceGrantStatus
                        platform="Meta"
                        accounts={accounts}
                        requestedServices={requestedServices}
                        service={s as AnyService}
                        serviceConfig={serviceConfig}
                    />
                })}
            </Box>
            <Button mt={10} fullWidth loading={loading} leftSection={<CircleCheck size={18} />} color={theme.color} onClick={async () => {
                setLoading(true)
                await markServiceGranted(invite.id, "Meta", "Meta Ads").finally(() => {
                    setLoading(false)
                })
            }}>{"Mark as Granted"}</Button>
        </>
    )
}

const serviceConfigAPIRoutes = {
    "Meta Ads": "/providers/facebook/ads/accounts",
    "Facebook Pages": "/providers/facebook/pages/accounts",
    "Instagram": "/providers/facebook/instagram/pages",
    "Facebook Product Catalog": "/providers/facebook/catalog/catalogs",
    "Datasets": "/providers/facebook/dataset/datasets",
} as Record<AnyService, string>

const AssetsSelector = ({ requestedServices, inviteID, serviceConfig, grantStatus, setServiceConfig, business, setAccounts, next, accounts }: { requestedServices: RequestedServices, grantStatus: GrantsStatus, next: () => void, setAccounts: (a: Array<{ account_id: string, id: string }>, s: AnyService) => void, accounts: Record<AnyService, Array<{ account_id: string, id: string }>>, inviteID: string, business: { name: string, id: string }, serviceConfig: BaseServiceConfig, setServiceConfig: (s: BaseServiceConfig) => void }) => {
    const platforms = useContext(PlatformsContext)
    const invite = useContext(InviteContext)
    const manualGrants = useContext(ManualGrantsContext)
    const theme = useContext(ThemeContext)
    // Pages + Catalog are automatic grants but the rest are manual. We'll deal with the automatic grants first then manual services on the next page
    const {
        error,
        errorList,
        loading,
        sendGrantRequest,
        setLoading,
        setError,
        setErrorList
    } = useGrantRequest()

    const allAutomaticGranted = useCallback((grantStatus: GrantsStatus) => {
        const remainingAutoServices = Object.keys(grantStatus).filter(s => !manualGrants.includes(s as AnyService) && !grantStatus[s as AnyService]?.granted)
        return !remainingAutoServices.length
    }, [])

    return (
        <>
            <Box bg={theme.theme === "dark" ? "rgb(26, 32, 44)" : "gray.0"} p="sm" style={{ borderRadius: 10, gap: 5 }} mt={10} className='flex fdc'>
                {Object.keys(requestedServices).map((s, i) => <Box key={i} className='flex aic jcsb'>
                    <Box style={{ gap: 7 }} className='flex'>
                        <Box bg={"#fff"} className='flex aic jcc' style={{ borderRadius: 100, width: 25, height: 25, overflow: "hidden" }}>
                            {loading && (!grantStatus || !grantStatus[s as AnyService]?.granted) && serviceConfig[s]
                                ? <Loader size={18} />
                                : <Image width={18} height={18} alt={`${s} logo`} style={{ borderRadius: 0, objectFit: "contain", backgroundColor: "#fff", padding: 0 }} src={`/images/logos/${s?.toLowerCase().replaceAll(" ", "_")}.png`} />
                            }
                        </Box>
                        <div>
                            <Text fw={400} fz={"sm"}>{s}</Text>
                            <Text fz={"xs"} c="dimmed">{roleValueToLabel(s, requestedServices[s as AnyService].accessLevel, platforms)}</Text>
                        </div>
                    </Box>
                    {!grantStatus || !grantStatus[s as AnyService]?.granted
                        ? <Selector
                            grantStatus={grantStatus}
                            apiRoute={serviceConfigAPIRoutes[s as AnyService]}
                            businessName={business.name}
                            inviteID={inviteID}
                            service={s as AnyService}
                            serviceConfig={serviceConfig}
                            // @ts-ignore
                            setServiceConfigs={setServiceConfig}
                            setAccounts={(a) => setAccounts(a, s as AnyService)}
                            __facebookPagesForInstagramValidation={(s as AnyService) === "Instagram" ? accounts["Facebook Pages"] : undefined}
                        />
                        : <GrantedChip />
                    }

                </Box>)}
            </Box>
            <Button mt={10} fullWidth disabled={!Object.keys(serviceConfig).length || !Object.keys(serviceConfig).map((s) => serviceConfig[s].length).find((s) => s !== 0)} onClick={() => {
                if (allAutomaticGranted(grantStatus)) {
                    return next()
                }
                setError("")
                setErrorList([])
                sendGrantRequest(invite, serviceConfig, "Meta").then((newGrantStatus) => {
                    if (allAutomaticGranted(newGrantStatus) && Object.keys(requestedServices).filter((a) => manualGrants.includes(a as AnyService)).length && !error && !errorList?.length) {
                        next()
                    }
                })
            }}>Grant Access</Button>

            {error
                ? <Error mt={10}>{error}</Error>
                : <ErrorList mt={10} errors={errorList} />
            }
        </>
    )
}

const Step = ({ children, i, title, activeStep, setActiveStep, setHeight, buttonDisabled }: PropsWithChildren & { i: number, buttonDisabled?: boolean, title: string, activeStep: number, setHeight: (h: number) => void, setActiveStep: (s: number) => void }) => {
    const { height, ref } = useElementSize()

    useEffect(() => {
        if (activeStep === i) {
            setHeight(height)
        }
    }, [activeStep, height])

    return (
        <Box w={"100%"} ref={ref} className='flex fdc' style={{ gap: 5, position: "absolute", transition: "all .3s", transform: `translateX(${activeStep === i ? 0 : (i > activeStep ? 1000 : -1000)}px)` }}>
            <Box className='flex aic' style={{ gap: 10 }}>
                {!!activeStep
                    ? <ActionIcon variant="light" color="gray" size={"md"} radius={100} onClick={() => setActiveStep(activeStep - 1)}>
                        <ArrowLeft size={18} />
                    </ActionIcon>
                    : null
                }

                {/* {React.cloneElement(circleNumberIcons[i], { size: 20 })} */}
                <Text fw={600} lh={1}>{title}</Text>
            </Box>
            <Box>
                {children}
            </Box>
            {!buttonDisabled
                ? <Button mt={10} fullWidth onClick={() => setActiveStep(activeStep + 1)}>Next</Button>
                : null
            }

        </Box>
    )
}

const Instruction = ({ children, index, extend }: PropsWithChildren & { index: number, extend?: boolean }) => {
    return (
        <Box style={{ gap: 10 }} className='flex'>
            <Box className='flex aic fdc'>
                <Box mih={25} w={25} className='flex aic jcc' h={25} style={{ borderRadius: 100, border: "2px dashed var(--mantine-color-gray-4)" }} bg={"gray.2"}>
                    <Text lh={1} fw={600} c="dimmed">{index}</Text>
                </Box>
                {extend
                    ? <Divider h={"calc(100% + 20px)"} style={{ borderWidth: 2 }} orientation='vertical' mx={"auto"} />
                    : null
                }
            </Box>
            {children}
        </Box>
    )
}

export const MetaPlatformWideGuide = ({ invite }: PlatformWideGuideProps) => {
    const [step, setStep] = useState(0)
    const { grantsStatus, setGrantsStatus } = useGrantStatus(invite, "Meta", false)
    // const [grantsStatus, setGrantsStatus] = useState<GrantsStatus | undefined>(undefined)
    const [agencyBusinessID, setAgencyBusinessID] = useState<string | undefined>(undefined)
    const [serviceConfig, setServiceConfig] = useState<BaseServiceConfig>({})
    // @ts-ignore
    const [accounts, setAccounts] = useState<Record<AnyService, Array<{ account_id: string, id: string }>>>({})
    const [height, setHeight] = useState(0)

    const manualGrants = useContext(ManualGrantsContext)

    console.log(serviceConfig)
    useEffect(() => {
        api("/invite/details/meta/partner-id", {
            inviteID: invite.id,
            service: "Meta Ads" as AnyService
        }).then((res) => {
            setAgencyBusinessID(res.data)
        })
    }, [])

    console.log("Grant status current:", grantsStatus)

    const anyAutomaticServicesGranted = Object.keys(grantsStatus).filter((s) => !manualGrants.includes(s as AnyService) && grantsStatus[s as AnyService]?.granted).length

    if (!invite.creds?.Meta) {
        return <Box pt={30} pb={20} className='flex aic jcc fdc' style={{ gap: 0 }}>
            <InfoCircle color='var(--mantine-color-dimmed)' size={20} />
            <Text c={"dimmed"} fw={500} fz={"md"}>Please Sign in with Meta to continue</Text>
        </Box>
    }

    return (
        <>
            <Divider mt={12} opacity={.5} />
            <Box mt={20}>
                <div className='flex fdc' style={{ position: "relative", height: height, transition: "all .3s" }}>
                    <Step setActiveStep={setStep} setHeight={setHeight} buttonDisabled activeStep={step} i={0} title='Select Assets'>
                        <Text fz={"sm"}>Select the assets you'd like to grant access to</Text>
                        <AssetsSelector
                            grantStatus={grantsStatus}
                            next={() => setStep(s => s + 1)}
                            inviteID={invite.id}
                            // @ts-ignore
                            business={invite.creds.Meta.business}
                            serviceConfig={serviceConfig}
                            setServiceConfig={(s: BaseServiceConfig) => setServiceConfig((prev) => ({ ...prev, ...s }))}
                            requestedServices={invite.requestedAccounts["Meta"]}
                            accounts={accounts}
                            setAccounts={(a, s) => setAccounts(prev => ({ ...prev, [s]: a }))}
                        />
                    </Step>
                    <Step setActiveStep={setStep} setHeight={setHeight} activeStep={step} i={1} title='Navigate to Meta Business Suite'>
                        <InstructionalImageRenderer path="/images/invite/guides/meta/2.gif" />
                        <Box mt={20}>
                            <Instruction extend index={1}>
                                {/* @ts-ignore */}
                                <Text fz="sm" mb={20}>Head over to <InlinePageLink href={`https://business.facebook.com/settings/partners/?business_id=${invite.creds.Meta.business.id as string}`} newTab>Meta Business Suite</InlinePageLink></Text>
                            </Instruction>
                            <Instruction extend index={2}>
                                <Text fz="sm" mb={20}>Navigate to <Text span fz="sm" fw={600}><Settings size={13} /> Settings</Text> in the bottom left</Text>
                            </Instruction>
                            <Instruction index={3}>
                                <Text fz="sm" mb={0}>In the sidebar, under Users, select Partners</Text>
                            </Instruction>
                        </Box>
                        {/* @ts-ignore */}
                        {/* <Link target='_blank' href={`https://business.facebook.com/settings/partners/?business_id=${invite.creds.Meta.business.id as string}`}>
                            <Button onClick={() => setStep((s) => s + 1)} fullWidth mt={10} variant="outline" rightSection={<ExternalLink size={20} />}>Go to Business Suite</Button>
                        </Link> */}
                    </Step>
                    <Step buttonDisabled setActiveStep={setStep} setHeight={setHeight} activeStep={step} i={2} title={anyAutomaticServicesGranted ? "Share Assets" : 'Add a new partner'}>
                        {/* If any automatic services have been granted we'll show the guide for exisitng partners */}
                        {anyAutomaticServicesGranted
                            ? <InstructionalImageRenderer path="/images/invite/guides/meta/4.gif" />
                            : <InstructionalImageRenderer path="/images/invite/guides/meta/3.gif" />
                        }
                        {/* <InstructionalImageRenderer path="/images/invite/guides/meta/3.gif" /> */}
                        <Box mt={20}>
                            {anyAutomaticServicesGranted
                                ? <>
                                    <Instruction extend index={1}>
                                        <Text mb={15} fz="sm">Ensure the correct partner in selected from the menu on the right and click the <strong>Share Assets</strong> button.</Text>
                                    </Instruction>
                                </>
                                : <>
                                    <Instruction extend index={1}>
                                        <Box mb={20}>
                                            <Text mt={0} fz="sm">Click the <strong>Add</strong> button and select <strong>Give a partner access to your assets</strong> from the dropdown.</Text>
                                            <Text mt={10} fz="sm">In the Partner Business ID box, type:</Text>
                                            <CopyBox mt={5} value={agencyBusinessID || ""} />

                                        </Box>

                                    </Instruction>
                                </>
                            }

                        </Box>
                        <Instruction index={2}>
                            <Box w={"100%"}>
                                <Text fz="sm">You should now see a list of assets you can grant access to. <br /><strong>Select the following assets:</strong></Text>
                                {step === 2
                                    ? <GrantStatuses
                                        accounts={accounts}
                                        requestedServices={invite.requestedAccounts["Meta"]}
                                        serviceConfig={serviceConfig}
                                    />
                                    : null
                                }

                            </Box>
                        </Instruction>
                        {/* <InstructionalImageRenderer path='/images/invite/guides/meta/1.png' /> */}
                    </Step>
                    {/* <Step setActiveStep={setStep} setHeight={setHeight} activeStep={step} i={3} title='Select Assets'>
                        <Text fz="sm">You should now see a list of assets you can grant access to. <br /><strong>Select the following assets:</strong></Text>
                        {step === 3
                            ? <GrantStatuses
                                accounts={accounts}
                                requestedServices={invite.requestedAccounts["Meta"]}
                                serviceConfig={serviceConfig}
                            />
                            : null
                        }

                    </Step> */}
                </div>
            </Box>
        </>
    )
}

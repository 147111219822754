"use client"
import { UserContext } from '@/utils/context/User.context'
import { Box, Button, Collapse, Divider, Modal, Paper, Text, Tooltip } from '@mantine/core'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { InfoSquare, PlayerPlay, Presentation } from 'tabler-icons-react'

const WelcomeModal = ({ close }: { close: () => void }) => {
    const [expanded, setExpanded] = useState(false)
    const user = useContext(UserContext)
    useEffect(() => {
        setTimeout(() => {
            setExpanded(true)
        }, 1500)
    }, [])

    return (
        <Box className='flex aic jcc fdc'>
            <Box className='flex aic jcc fdc' p="lg" pt={12}>
                <Text fz={50} className='wave'>👋</Text>
                <Text ta="center">
                    <Text lh={1} style={{ textTransform: "uppercase" }} fw={900} fz="lg" c="dimmed">Welcome</Text>
                    <Text lineClamp={1} ta={"center"} fz={30} fw={800}>Hey, {user?.name}</Text>
                </Text>

            </Box>
            <Collapse transitionDuration={800} in={expanded}>
                <Text mt={0} c="dimmed" ta="center" fw={500}>Ready to take client onboarding to the next level? Get started by watching this quick onboarding video.</Text>
                <Paper radius={10} shadow='xs' withBorder style={{ overflow: "hidden" }} mt={15} h="fit-content">
                    <video
                        style={{ width: "100%" }}
                        controls
                        src="https://storage.googleapis.com/agencyaccess-branding-assets/videos/Reports%20dashboard%20-%2030%20April%202024.mp4"
                    />
                </Paper>
                <Paper withBorder p={"xs"} radius={10} mt={10} shadow='xs'>
                    <Box className='flex' style={{ gap: 10 }}>
                        <InfoSquare style={{ minWidth: 15 }} size={15} />
                        <Text lh={1.2} fz="sm">Want to follow along? You can dismiss this video and come back to it by clicking <Presentation size={14} /> the button in the header</Text>
                    </Box>
                </Paper>
                {/* <iframe src="https://storage.googleapis.com/agencyaccess-branding-assets/videos/Reports%20dashboard%20-%2030%20April%202024.mp4" style={{ width: "100%", aspectRatio: "16 / 9", marginTop: 10, borderRadius: 10 }} allow="autoplay"></iframe> */}
                {/* <video src="https://drive.google.com/file/d/1Z6s55h4IA6GuI-cXek-2pxrpGcJBuByH/view?usp=sharing the vid" width={"100%"}></video> */}
                {/* <Box mt={20} bg={"gray.0"} w="100%" style={{ aspectRatio: "16 / 9", borderRadius: 15 }} className="flex aic jcc">
                    <PlayerPlay size={35} />
                </Box> */}
                <Button mt={20} mb={15} size="md" fullWidth color={"blue"} onClick={close}>Continue</Button>
            </Collapse>
        </Box >
    )
}

export default function WelcomeModalController() {
    const [open, setOpen] = useState(false)
    const router = useRouter()
    useEffect(() => {
        const { first_visit } = router.query
        if (first_visit) {
            setTimeout(() => {
                setOpen(true)
            }, 800)
        } else {
            setOpen(false)
        }
    }, [router])
    const close = () => {
        (function (history) {
            var replaceState = history.replaceState;
            history.replaceState = function (state) {
                // @ts-ignore
                if (typeof history.onpushstate == "function") {
                    // @ts-ignore
                    history.onpushstate({ state: state });
                }
                // ... whatever else you want to do
                // maybe call onhashchange e.handler
                // @ts-ignore
                return replaceState.apply(history, arguments);
            };
        })(window.history);
        setOpen(false)
        window.history.replaceState({}, document.title, "/" + "dashboard")
    }
    return (
        <Modal
            radius={"xl"}
            opened={open}
            onClose={close}
            withCloseButton={false}
            centered
            zIndex={100000}
            // styles={{ content: { background: "transparent", boxShadow: "none" } }}
            transitionProps={{
                transition: "pop",
                duration: 1000,
                exitDuration: 300
            }}
        >
            <WelcomeModal close={close} />
        </Modal>
    )
}

import React from "react";
import { Article, ChartBar, Click, DeviceDesktopAnalytics, Discount, Forms, Link, Message, Paint, QuestionMark, Tag, Upload, UserMinus, UserPlus, Users } from "tabler-icons-react";

type Route = { label: string, path: string, icon: React.ReactElement, new?: boolean }

export const dashboardRoutes = [
    {
        label: "Dashboard",
        path: "/dashboard",
        icon: <DeviceDesktopAnalytics />
    },
    {
        label: "Clients",
        path: "/dashboard/clients",
        icon: <Users />
    },
    {
        label: "Prospects",
        path: "/dashboard/prospects",
        icon: <UserMinus />
    },
    {
        label: "Static Links",
        path: "/dashboard/links",
        icon: <Click />,
        // new: true
    },
    {
        label: "Accounts",
        path: "/dashboard/accounts",
        icon: <Link />
    },
    {
        label: "Branding",
        path: "/dashboard/branding",
        icon: <Paint />
    },
    {
        label: "Intake Form",
        path: "/dashboard/intake",
        icon: <Forms />
    }
] as Array<Route>

export const adminDashboardRoutes = [
    {
        label: "Dashboard",
        path: "/admin",
        icon: <DeviceDesktopAnalytics />
    },
    {
        label: "Updates",
        path: "/admin/updates",
        icon: <Upload />
    },
    {
        label: "Coupons",
        path: "/admin/coupons",
        icon: <Tag />
    },
    {
        label: "Blog",
        path: "/admin/studio",
        icon: <Article />
    }
] as Array<Route>